import * as React from 'react'
import { Link, navigate } from 'gatsby'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { Box, Container } from '@mui/system'
import { Grid } from '@mui/material'
import Item from '@mui/material/Box'
import { StaticImage } from "gatsby-plugin-image"

const Questions = () => (
    <>
      <Box>
        <Container sx={{paddingBottom : '30px', marginBottom:'18px'}}>
            <Typography variant='h3' marginBottom='29px' marginTop='29px'>
                Tienes Preguntas?
            </Typography>
            <Container sx={{backgroundColor: (theme) => theme.palette.secondary.light, }}>
                <Grid container direction='row' gap='10px' sx={{paddingTop:'20px', paddingBottom: '20px',}}>
                    <Item width='31%'>
                        <StaticImage  src='../../images/lawyer.png'/>            
                    </Item>
                    <Item width='61%'>
                        <Typography fontWeight='700' fontSize='16px' marginBottom='16px' variant='h3'>Consulta con un abogado</Typography>
                        <Typography > Responde tus dudas con un abogado migratorio</Typography>
                        <Button variant='text'>Conocer más</Button>
                    </Item>
                </Grid>
            </Container>
        </Container>
      </Box>
    </>
  )
  
  export default Questions