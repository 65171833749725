import * as React from 'react'
import { Link, navigate } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import Steps from '../components/profile/steps'
import AccordionSteps from '../components/profile/accordion-steps'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import ContentContainer from '../components/content-container'
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Questions from '../components/profile/questions'
import DrawerAppBar from './../components/app-bar'

const data = 
  {
    name: 'Luis',
    free_assessment_results_old: '12345',
    paid_assessment_taken: 'true',
    paid_assesment_results: '12345',
    paid_assesment_paid: 'true',
  }
const links = [
  {
    text: 'Example Movigy Assessment Result',
    url: '/resultado/5311504107819565529',
    description:
      'An example assessment result using the react sdk and rest api to retrieve the data after the initial load and show a loading spinner.',
  },
  {
    text: 'Visas List Page',
    url: '/visas',
    description:
      "A page used to list out all available visas in order to demonstrate reading data using gatsby's graphql layer to generate a static page for each visa",
  },
  {
    text: 'Example About us',
    url: '/sobre-nosotros',
    description: 'A placeholder for the about us page copied from the old site',
  },
]

const samplePageLinks = [
  {
    text: 'Page 2',
    url: 'page-2',
    badge: false,
    description:
      'A simple example of linking to another page within a Gatsby site',
  },
  { text: 'TypeScript', url: 'using-typescript' },
  { text: 'Server Side Rendering', url: 'using-ssr' },
  { text: 'Deferred Static Generation', url: 'using-dsg' },
]

const moreLinks = [
  { text: 'Join us on Discord', url: 'https://gatsby.dev/discord' },
  {
    text: 'Documentation',
    url: 'https://gatsbyjs.com/docs/',
  },
  {
    text: 'Starters',
    url: 'https://gatsbyjs.com/starters/',
  },
  {
    text: 'Showcase',
    url: 'https://gatsbyjs.com/showcase/',
  },
  {
    text: 'Contributing',
    url: 'https://www.gatsbyjs.com/contributing/',
  },
  { text: 'Issues', url: 'https://github.com/gatsbyjs/gatsby/issues' },
]

// const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <DrawerAppBar />
    <Box sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
      <Layout>
        <Steps data={data} />
        <AccordionSteps data={data} />
        <Questions />
      </Layout>
    </Box>
  </>
)

export default withAuthenticationRequired(IndexPage)
