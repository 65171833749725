import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Item from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const CustomAccordion = () => {
  return (
    <Container sx={{paddingRight:'0px', paddingLeft:'0px'}}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Typography borderRadius={'100%'} color='text.light' padding={'2px 9px'} margin='10px 10px 10px 0px' sx={{ backgroundColor: (theme) => theme.palette.primary.light,}} >3</Typography>
        <Typography variant='h3' color='text.title'>Aplica a la visa</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container sx={{borderColor: (theme) => theme.palette.primary.main, borderStyle:'solid' , borderWidth : 'thin', paddingTop:'10px', paddingBottom:'10px', borderRadius:'3px', marginBottom:'15px'}}>
          <Typography color='primary.main' fontWeight={'600'} variant='p' marginBottom={'30px'}>No has validado que cumples con todos los requisitos para aplicar a una visa. </Typography>
          <Typography color='text.secondary' marginTop={'30px'} fontWeight='400' fontSize={'14px'} > Te recomendamos realizar el paso 2 antes de comenzar el proceso con una visa.</Typography>
        </Container>
        <Typography>
          Comienza el proceso con la visa que elegiste.
        </Typography>
        <Button variant='contained'  sx={{marginTop:'20px',}}><Typography color='text.light'>Comenzar</Typography></Button>
      </AccordionDetails>
    </Accordion>
  </Container>
  )
}
const Success = () =>{
  return (
    <Box>
    <Container sx={{
      padding: '30px 20px',
    }}>
      <Grid >
        <Item >
          <Grid container >
            <Item sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              padding: '5px',
              borderRadius:'2px',
              color: (theme) => theme.palette.secondary.main,
              textTransform: 'uppercase',
              display:'flex',
              alignItems:'center',
              alignContent:'center',
            }}>
            <FontAwesomeIcon fontSize='15px' className='star' icon={faStar} />
            <Typography fontSize={'10px'} fontWeight='700'> Recomendado para ti</Typography>
            </Item>
          </Grid> 
        </Item>
        <Item >
        <Grid container direction="row" alignItems={'center'}>
          <Typography borderRadius={'100%'} color='secondary.main' padding={'2px 9px'} margin='10px 10px 10px 0px' border={'1px solid '} >3</Typography>
          <Typography fontWeight='700' fontSize={'20px'} variant='h3'>Aplica a la visa </Typography>
        </Grid>
        </Item>
        <Item>
        <Typography variant='p'  color='text.secondary'>Comienza el proceso con la visa que elegiste.</Typography>
        </Item>
        <Item>
        <Button variant='contained' sx={{marginTop:'20px',}}><Typography color='text.light'> Comenzar</Typography></Button>
        </Item>
      </Grid>
    </Container>
  </Box>
  )
}

const ThirdStep = ({ data }) => {
  const result = data.free_assessment_results_old
  const result_paid =  data.paid_assessment_taken
  const paid = data.paid_assesment_paid

  return(
    result ? result_paid ? paid ? <Success /> : <CustomAccordion /> : <CustomAccordion /> : <CustomAccordion /> 
  )
}

export default ThirdStep
