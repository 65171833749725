import React, {useState, useEffect} from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Item from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import './steps.scss'
import Stepsbg from '../../images/steps-bg.png' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

const Steps = ({data}) => {

  const activeStep = (stepNum) => {
    return(
      stepNum === 1 ? !data.free_assessment_results_old ? 'steps active' : 'steps' :
      stepNum === 2 ?  data.paid_assessment_taken && data.paid_assesment_paid ? 'steps ' : !data.free_assessment_results_old ? 'steps' : 'steps active 2':
      stepNum === 3 ? data.free_assessment_results_old && data.paid_assesment_paid && data.paid_assessment_taken ? 'steps active' : 'steps' : 'steps'
    )
  }  

  const maxHeight = '80vh'

  return (
    <Box
      sx={{
        display: 'grid',
        maxHeight,
      }}
    >
      <Container
        className='steps-container'
        sx={{
          padding: '35px 20px',
          backgroundImage: 'url('+Stepsbg+')',
        }}
      >
        <Grid container direction="column">
            <Item>
                <Typography variant='h2' color="text.light" sx={{marginBottom:'5px',}}>
                    Hola {data.name}!
                </Typography>
            </Item>
            <Item>
                <Typography variant='p' color="text.light">
                Bienvenida a tu home migratorio
                </Typography>
            </Item>
        </Grid>
        <Grid container direction="row">
            <Item 
              className={activeStep(1)}
            >
              <Typography className='title'>
                {activeStep(2)=== 'steps active 2' ? <FontAwesomeIcon   icon={faCircleCheck} /> : ''}  
                {activeStep(3)=== 'steps active' ? <FontAwesomeIcon  fontSize='13px' icon={faCircleCheck} /> : ''}
                Paso 1
              </Typography>
              <Typography className='description'>
                Descubre
              </Typography>
                
            </Item>
            <Item className={activeStep(2)}>
              <Typography className='title'>
              {activeStep(3)=== 'steps active' ? <FontAwesomeIcon   icon={faCircleCheck} /> : ''} Paso 2
              </Typography>
              <Typography className='description'>
                Elige
              </Typography>
            </Item>
            <Item className={activeStep(3)}>
            <Typography className='title'>
                Paso 3
              </Typography>
              <Typography className='description'>
                Aplica
              </Typography>
            </Item>
        </Grid>
      </Container>
    </Box>
  )
}

export default Steps
