import * as React from 'react'
import FirstStep from '../accordion-steps/first-step'
import SecondStep from '../accordion-steps/second-step'
import ThirdStep from '../accordion-steps/third-step'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import './accordion.scss'

const SecondStepNoData = () => {
  return (
    <Container  sx={{paddingRight:'0px', paddingLeft:'0px'}}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        className='accordion-summary'
      >
        <Typography borderRadius={'100%'} color='text.light' padding={'2px 9px'} margin='10px 10px 10px 0px' sx={{ backgroundColor: (theme) => theme.palette.primary.light,}} >2</Typography>
        <Typography variant='h3' color='text.title'>Elige la mejor visa para ti</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        Antes de que podamos ayudarte a decidir cuál es la mejor visa para tí deberas completar el primer paso ¡es gratis y tendrás tus resultados al instante!
        </Typography>
      </AccordionDetails>
    </Accordion>
  </Container>
  )
}
const ThirdStepNoData = () => {
  return(
    <Container sx={{paddingRight:'0px', paddingLeft:'0px'}}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
                <Typography borderRadius={'100%'} color='text.light' padding={'2px 9px'} margin='10px 10px 10px 0px' sx={{ backgroundColor: (theme) => theme.palette.primary.light,}} >3</Typography>
        <Typography variant='h3' color='text.title'>Aplica a la visa</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        Antes de aplicar a la visa con Movigy deberás completar al menos el primer paso ¡es gratis y tendrás tus resultados al instante!
        </Typography>
      </AccordionDetails>
    </Accordion>
  </Container>
  )
}
const AccordionSteps  = ({data}) => {
  const free_assessment = data.free_assessment_results_old
  if(free_assessment){
    return (
      <Box className='accordion-container'>
        <FirstStep data={data}/>
        <SecondStep data={data} />
        <ThirdStep data = {data} />
      </Box>
    )
  }else{
    return (
      <Box  className='accordion-container'>
        <FirstStep data={data}/>
        <SecondStepNoData data={data} />
        <ThirdStepNoData data = {data} />
      </Box>
    )
  }
 
}

export default AccordionSteps ;