import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Item from '@mui/material/Box'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


const CustomAccordion = () => {
  return (
  <Container  sx={{paddingRight:'0px', paddingLeft:'0px'}}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography borderRadius={'100%'} color='text.light' padding={'2px 9px'} margin='10px 10px 10px 0px' sx={{ backgroundColor: (theme) => theme.palette.primary.light,}} ><FontAwesomeIcon fontSize='15px'  icon={faCheck} /></Typography>
        <Typography variant='h3' color='text.title'> Descubre tus visas</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        Felicitaciones! Has descubierto tus opciones de visas. <Typography fontWeight={'800'} variant='p'>Continúa al siguiente paso </Typography>  y conoce la mejor opción de visa para ti.
        </Typography>
        <Button variant='text'>Ver Resultados</Button>
      </AccordionDetails>
    </Accordion>
  </Container>
  )
}
const CompleteFirstStep = () =>{
  return (
    <Box>
      <Container className='accordion-start' sx={{
        padding: '30px 20px',
      }}>
        <Grid >
          <Item >
            <Grid container >
              <Item sx={{
                backgroundColor: (theme) => theme.palette.secondary.light,
                padding: '5px',
                borderRadius:'2px',
                color: (theme) => theme.palette.secondary.main,
                textTransform: 'uppercase',
                display: 'flex',
                justifyContent:'center',
                alignContent:'center',
                display:'flex',
                alignItems:'center',
                alignContent:'center',
              }}>
              <FontAwesomeIcon fontSize='15px' className='star' icon={faStar} />
              <Typography fontSize={'10px'} fontWeight='700'>  Recomendado para ti</Typography>
              </Item>
            </Grid> 
          </Item>
          <Item >
          <Grid container direction="row" alignItems={'center'}>
            <Typography borderRadius={'100%'} color='secondary.main' padding={'2px 9px'} margin='10px 10px 10px 0px' border={'1px solid '} >1</Typography>
            <Typography fontWeight='700' fontSize={'20px'} variant='h3'> Descubre tus visas </Typography>
          </Grid>
          </Item>
          <Item>
          <Typography variant='p'  color='text.secondary'>Conoce GRATIS tus opciones de visa para emigrar a los Estados Unidos. Resultados en 5 minutos</Typography>
          </Item>
          <Item>
          <Button variant='contained' sx={{marginTop:'20px',}}><Typography color='text.light'> Comenzar</Typography></Button>
          </Item>
        </Grid>
      </Container>
    </Box>
  )
}

const FirstStep = ({ data }) => {
  const result = data.free_assessment_results_old

  if (!result) {
    return <CompleteFirstStep/>
  } else {
    return <CustomAccordion/>
  }
}

export default FirstStep
