import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Item from '@mui/material/Box'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const CustomAccordion = () => {
  return (
    <Container sx={{paddingRight:'0px', paddingLeft:'0px'}}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography borderRadius={'100%'} color='text.light' padding={'2px 9px'} margin='10px 10px 10px 0px' sx={{ backgroundColor: (theme) => theme.palette.primary.light,}} ><FontAwesomeIcon fontSize='15px'  icon={faCheck} /></Typography>
        <Typography variant='h3' color='text.title'>Elige la mejor visa para ti</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        Felicitaciones! Has validado tus opciones de visas. Si estás conforme con tu elección,  <Typography fontWeight={'800'} variant='p'>Continúa al siguiente paso </Typography> 
        </Typography>
        <Button variant='text'>Ver Resultados</Button>
      </AccordionDetails>
    </Accordion>
  </Container>
  )
}
const CompleteSecondStep = () =>{
  return (
    <Box>
    <Container sx={{
      padding: '30px 20px',
    }}>
      <Grid >
        <Item >
          <Grid container >
            <Item sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              padding: '5px',
              borderRadius:'2px',
              color: (theme) => theme.palette.secondary.main,
              textTransform: 'uppercase',
              display:'flex',
              alignItems:'center',
              alignContent:'center',
            }}>
            <FontAwesomeIcon fontSize='15px' className='star' icon={faStar} />
            <Typography fontSize={'10px'} fontWeight='700'>Recomendado para ti</Typography>
            </Item>
          </Grid> 
        </Item>
        <Item >
        <Grid container direction="row" alignItems={'center'}>
          <Typography borderRadius={'100%'} color='secondary.main' padding={'2px 9px'} margin='10px 10px 10px 0px' border={'1px solid '} >2</Typography>
          <Typography fontWeight='700' fontSize={'20px'} variant='h3'> Decide tu visa </Typography>
        </Grid>
        </Item>
        <Item>
        <Typography variant='p'  color='text.secondary'>Conoce la mejor visa para ti. Analizaremos si cumples con todos los requisitos y conoce cómo mejorar tus chances de obtener tu visa por sólo $30USD. Resultados en 5 minutos.</Typography>
        </Item>
        <Item>
        <Button variant='contained' sx={{marginTop:'20px',}}><Typography color='text.light'> Comenzar Gratis</Typography></Button>
        </Item>
      </Grid>
    </Container>
  </Box>
  )
}
const PaidAssesment = () => {
  return (
    <Box>
    <Container sx={{
      padding: '30px 20px',
    }}>
      <Grid >
        <Item >
          <Grid container >
            <Item sx={{
              backgroundColor: (theme) => theme.palette.secondary.light,
              padding: '5px',
              borderRadius:'2px',
              color: (theme) => theme.palette.secondary.main,
              textTransform: 'uppercase',
              display:'flex',
              alignItems:'center',
              alignContent:'center',
            }}>
            <FontAwesomeIcon fontSize='15px' className='star' icon={faStar} />
            <Typography fontSize={'10px'} fontWeight='700'> Recomendado para ti</Typography>
            </Item>
          </Grid> 
        </Item>
        <Item >
        <Grid container direction="row" alignItems={'center'}>
          <Typography borderRadius={'100%'} color='secondary.main' padding={'2px 9px'} margin='10px 10px 10px 0px' border={'1px solid '} >2</Typography>
          <Typography fontWeight='700' fontSize={'20px'} variant='h3'> Decide tu visa </Typography>
        </Grid>
        </Item>
        <Item>
        <Typography variant='p'  color='text.secondary'>Te encuentras cada vez mas cerca de tu objetivo. <Typography fontStyle={'bold'}>Completa la compra para acceder a tus resultados</Typography></Typography>
        </Item>
        <Item>
        <Button variant='contained' sx={{marginTop:'20px',}}><Typography color='text.light'> Desbloquear Resultados</Typography></Button>
        </Item>
      </Grid>
    </Container>
  </Box>
  )
}

const SecondStep = ({ data }) => {
  const result = data.paid_assessment_taken
  const paid = data.paid_assesment_paid

  if(!result){
    return <CompleteSecondStep />
  }else{
    if(!paid){
      return <PaidAssesment />
    } else {
      return <CustomAccordion />
    }
  }
}

export default SecondStep
